export default {
  END_POINT: process.env.REACT_APP_API_BASE_URL || 'https://homol.partner.gateway.pay2b.net',
  EXPIRATION_AUTH: 2000, // Minutes
  EXPIRATION_FORM: 5, // Minutes
  DEBUG: {
    LEVEL: 'debug',
    AVAILABLE: true,
    LOCAL: true,
  },
  BRAINTREE: {
    MERCHANT_ID: process.env.BRAINTREE_MERCHANT_ID || 'jr7yzsfyf5zjnpyd',
    PUBLIC_KEY: process.env.BRAINTREE_PUBLIC_KEY || '5dg78b8y9wndtyh5',
    PRIVATE_KEY: process.env.BRAINTREE_PRIVATE_KEY || '9753b17e53ca9b7d8e4cedcf11b60a2d',
    IS_SANDBOX: process.env.BRAINTREE_IS_SANDBOX || true,
  },
  CHECKOUT: {
    URL: '/checkout',
    PAY: '/pay',
  },
  OPENFINANCE: {
    URL: '/openfinance',
    PARTICIPANTS: '/participants',
  },
  RESUME: {
    URL: '/checkout',
    VALIDATE: '/validate',
  },
  CARD: {
    URL: '/checkout',
    CREDIT_CARD: '/credit_card',
  },
  TOKEN: {
    URL: '/checkout',
    REQUEST: '/token',
    CONFIRM: '/confirm',
  },
};
